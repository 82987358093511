<template>
  <main>
    <CCard>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.Products/StockImport') }}</h5>
          </CCol>
          <CCol col="6" class="text-md-right">
            <CButton color="success" variant="outline" shape="pill" @click="OpenStockImportOrder()">
              <CIcon size="sm" class="mr-1" name="cil-plus"/> {{ $t('Product/List.Import') }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-2">
        <CRow class="text-nowrap">
          <CCol col="12">
            <CButton color="white" shape="pill" class="mr-2" @click="OpenFilter('All')">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
              {{ $t('Order.Filter.DataTime') }}: {{
                SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD') : '--'
              }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD') : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Buyer')">
              {{ $t('Order.Buyer') }}: {{ SearchFilter.Buyer || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('OrderNum')">
              {{ $t('Product/Stock.ImportOrderNum') }}: {{ SearchFilter.OrderNum.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('ProductName')">
              {{ $t('Product/List.SKUName') }}: {{ SearchFilter.ProductName || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('CustomSeriesNum')">
              {{ $t('Product/List.SKU') }}: {{ SearchFilter.CustomSeriesNum.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('OrderStatus')">
              {{ $t('Product/Stock.ImportStatus') }}: {{ SearchFilter.OrderStatus.map(item => item.label).join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('PerPage')">
              {{ $t('Order.Filter.PerPage') }}: {{ Pagination.PerPage }} {{ $t('Global.OrderUnit') }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetState()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CDataTable
          id="OrderList"
          :items="List"
          :fields="Field"
          :loading="Loading"
          :noItemsView="noItemsView"
          addTableClasses="small text-nowrap"
          responsive
          hover
        >
          <template #Checkbox-header>
            <label class="mb-0">
              <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
            </label>
          </template>
          <template #Checkbox="{item}">
            <td class="text-center">
              <label class="mb-0">
                <input type="checkbox" :id="'Checkbox-List-' + item.OrderNum" :value="item.OrderNum" v-model="ChooseIndex">
              </label>
            </td>
          </template>
          <template #OrderNum="{item}">
            <td>
              {{ item.OrderNum }}
            </td>
          </template>
          <template #Operator="{item}">
            <td>
              {{ item.MemberInfo.Name }}
            </td>
          </template>
          <template #Amount="{item}">
            <td>
              {{ $store.state.currency + '$' }}{{ item.TotalAmount }}
            </td>
          </template>
          <template #OrderStatus="{item}">
            <td>
              <CBadge :color="StatusClassOption[item.Status.Order]">
                {{ $t('Product/Stock.ImportStatusOptions.' + item.Status.Order) }}
              </CBadge>
            </td>
          </template>
          <template #CreateTime="{item}">
            <td>
             {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}
            </td>
          </template>
          <template #Action="{item}">
            <td>
              <CButton color="info" size="sm" class="mr-1" @click="OpenStockImportOrder(item)">
                <CIcon name="cil-notes" class="c-icon-custom-size mr-1" />
                {{ $t('Global.Detail') }}
              </CButton>
              <CButton color="success" size="sm" class="mr-1" :disabled="item.Status.Order === 'Y'" @click="PutStock(item)">
                <CIcon name="cil-check-circle" class="c-icon-custom-size mr-1" />
                {{ $t('Product/Stock.Put') }}
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'] }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
      </CCardBody>
      <CCardFooter>
        <CPagination :activePage.sync="Pagination.Page" :pages.sync="Pagination.TotalPages" @update:activePage="ChangePage()"/>
      </CCardFooter>
    </CCard>
    <CModal id="FilterModel" class="ActionModel" :size="(ShowFilter !== 'All' ? 'md' : 'lg')" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit"
            @update:show="ChangePage()">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="date" :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="date" :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.TimeRangeOptions') }}
        </CCol>
        <CCol sm="9">
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().format('YYYY-MM-DD 00:00:00'), $dayjs().format('YYYY-MM-DD 23:59:59'))">
            今日
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00'), $dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59'))">
            昨日
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(6, 'day').format('YYYY-MM-DD 00:00:00'), $dayjs().format('YYYY-MM-DD 23:59:59'))">
            本週
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(2, 'week').format('YYYY-MM-DD 00:00:00'), $dayjs().subtract(1, 'week').format('YYYY-MM-DD 23:59:59'))">
            上週
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().format('YYYY-MM-01 00:00:00'), $dayjs().format('YYYY-MM-DD 23:59:59'))">
            本月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'), $dayjs().startOf('month').format('YYYY-MM-DD'))">
            上月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'), $dayjs().format('YYYY-MM-DD HH:mm:ss'))">
            近一個月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(3, 'month').format('YYYY-MM-DD HH:mm:ss'), $dayjs().format('YYYY-MM-DD HH:mm:ss'))">
            近三個月
          </CButton>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'OrderNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Stock.ImportOrderNum') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.OrderNum"
            v-model="SearchFilter.OrderNum"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="SearchFilter.OrderNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Buyer'" v-model="SearchFilter.Buyer" :label="$t('Order.Buyer')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'ProductName'" v-model="SearchFilter.ProductName" :label="$t('Product/List.SKUName')" type="text" horizontal />
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'CustomSeriesNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/List.SKU') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.CustomSeriesNum"
            v-model="SearchFilter.CustomSeriesNum"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="SearchFilter.CustomSeriesNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'OrderStatus'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Stock.ImportStatus') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.OrderStatus"
            v-model="SearchFilter.OrderStatus"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="ImportStatusOptions"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'Sort'" horizontal :label="$t('Order.Filter.Sort')" :options="SortOption" v-model="Order" :value.sync="Order" :placeholder="$t('Global.PleaseSelect')"/>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'PerPage'" horizontal :label="$t('Order.Filter.PerPage')" :options="PerPageOption" v-model="Pagination.PerPage" :value.sync="Pagination.PerPage" :placeholder="$t('Global.PleaseSelect')"/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangePage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <component :is="Component" :Toggle.sync="ToggleModal" :CurrentOrderID.sync="CurrentOrderID" @CreateOrder="Init()" />
  </main>
</template>

<route>
{
"meta": {
"label": "進貨管理"
}
}
</route>

<script>
export default {
  name: 'StockImport',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data() {
    return {
      Component: null,
      List: [],
      Features: [],
      StatisticsSwitch: 'Total',
      SubTotalStatistics: {},
      TotalStatistics: {},
      ShowFilter: 'All',
      SearchFilterType: null,
      CurrentOrderID: null,
      SearchFilter: {
        StartTime: '',
        EndTime: '',
        ReceiverDate: '',
        SearchAllTimeRange: false,
        OrderNum: [],
        Payment: [],
        Logistics: [],
        PaymentSystem: [],
        LogisticsSystem: [],
        PaymentStatus: [],
        LogisticsStatus: [],
        OrderStatus: [],
        Account: [],
        Buyer: '',
        Receiver: '',
        BuyerPhone: '',
        ReceiverPhone: '',
        BuyerEmail: '',
        ReceiverEmail: '',
        CustomSeriesNum: [],
        ProductName: '',
        OfferType: [],
        OfferID: [],
        InvoiceType: [],
        InvoiceNumber: '',
        LogisticsNum: [],
        Origin: [],
        Country: [],
        utm_id: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: ''
      },
      AllCondition: false,
      AlertModel: false,
      FilterModel: false,
      ToggleModal: false,
      SelectOrderNum: '',
      SelectOrderData: {},
      SelectSystem: '',
      StatusClassOption: {
        'P': 'warning',
        'Y': 'success'
      },
      ChooseIndex: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'nto',
      SearchAllTimeRange: true,
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Order.NoResults'),
        noItems: this.$t('Order.NoItems')
      },
      PageList: [
        { label: this.$t('Offer.OfficialWeb'), value: 'Default' }
      ],
      UserID: '',
      UserType: ''
    }
  },
  computed: {
    Field() {
      return [
        {key: 'Checkbox', label: ''},
        {key: 'OrderNum', label: this.$t('Product/Stock.ImportOrderNum')},
        {key: 'Operator', label: this.$t('Product/Stock.Operator')},
        {key: 'Amount', label: this.$t('Product/Stock.Amount')},
        {key: 'OrderStatus', label: this.$t('Product/Stock.Status')},
        {key: 'CreateTime', label: this.$t('Product/Stock.CreateTime')},
        {key: 'Action', label: ''},
      ]
    },
    SortOption() {
      return Object.keys(this.$t('Order/List.SortType')).map(type => {
        return {
          value: type,
          label: this.$t('Order/List.SortType')[type]
        }
      })
    },
    PerPageOption() {
      return Object.keys(this.$t('Order/List.PerPageOption')).map(option => {
        return {
          value: option,
          label: this.$t('Order/List.PerPageOption')[option]
        }
      })
    },
    ImportStatusOptions() {
      return Object.keys(this.$t('Product/Stock.ImportStatusOptions')).map(status => {
        return {
          value: status,
          label: this.$t('Product/Stock.ImportStatusOptions')[status]
        }
      })
    }
  },
  created() {
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    this.Features = PermissionSession.Features.Order || []
  },
  mounted() {
    this.$Progress.start()
    return this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList()
      ]).then(() => {
        Object.keys(this.$route.query).forEach((param) => {
          switch (param) {
            case 'page':
            case 'perPage':
            case 'order':
              break
            case 'PaymentStatus':
            case 'LogisticsStatus':
            case 'OrderStatus':
            case 'InvoiceType':
            case 'OfferType':
            case 'PaymentSystem':
            case 'LogisticsSystem':
              this.SearchFilter[param] = this[`${param}List`].filter(data => this.$route.query[param].split(',').includes(data.value))
              break
            case 'Payment':
            case 'Logistics':
              this.SearchFilter[param] = this[`${param}TypeList`].filter(data => this.$route.query[param].split(',').includes(data.value))
              break
            case 'StartTime':
            case 'EndTime':
              this.SearchFilter[param] = parseInt(this.$route.query[param]) * 1000
              break
            case 'Origin':
            case 'Country':
              this.SearchFilter[param] = this.PageList.filter(data => this.$route.query[param].split(',').includes(data.value))
              break
            case 'Buyer':
            case 'Receiver':
            case 'BuyerPhone':
            case 'ReceiverPhone':
            case 'BuyerEmail':
            case 'ReceiverEmail':
            case 'ProductName':
            case 'SearchAllTimeRange':
            case 'ReceiverDate':
            case 'utm_id':
            case 'utm_source':
            case 'utm_medium':
            case 'utm_campaign':
            case 'utm_term':
            case 'utm_content':
              this.SearchFilter[param] = this.$route.query[param]
              break
            default:
              this.SearchFilter[param] = this.$route.query[param].split(',')
              break
          }
        })
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      const RequestQuery = {
        Taxonomy: 'Import',
        Page: this.$route.query.page || this.Pagination.Page,
        PerPage: this.$route.query.perPage || this.Pagination.PerPage,
        Order: this.$route.query.order || this.Order,
        StartTime: this.$route.query.StartTime || this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).subtract(1, 'month').unix(),
        EndTime: this.$route.query.EndTime || this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).add(1, 'day').unix(),
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        if (typeof this.$route.query[param] !== 'undefined') {
          RequestQuery[param] = this.$route.query[param]
        }
      })
      this.ChooseIndex = []
      this.List = []
      this.SubTotalStatistics = {}
      this.TotalStatistics = {}
      this.Loading = true
      this.FilterModel = false
      return this.$store.dispatch('InnerRequest', {
        url: '/order/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.SubTotalStatistics = data.subTotalStatistics || {}
        this.TotalStatistics = data.totalStatistics || {}
        this.Pagination = data.pagination
        this.Pagination = {
          Page: data.pagination.Page,
          PerPage: data.pagination.PerPage.toString(),
          TotalItems: data.pagination.TotalItems,
          TotalPages: data.pagination.TotalPages
        }
        this.SearchFilter.StartTime = data.pagination.TimeRange.StartTime
        this.SearchFilter.EndTime = data.pagination.TimeRange.EndTime
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        switch (typeof this.SearchFilter[param]) {
          case "object":
            if (Array.isArray(this.SearchFilter[param]) && this.SearchFilter[param].length > 0) {
              switch (param) {
                case 'PaymentStatus':
                case 'LogisticsStatus':
                case 'OrderStatus':
                case 'Payment':
                case 'Logistics':
                case 'PaymentSystem':
                case 'LogisticsSystem':
                case 'InvoiceType':
                case 'OfferType':
                case 'Origin':
                case 'Country':
                  query[param] = this.SearchFilter[param].map(item => item.value).join(',')
                  break
                default:
                  query[param] = this.SearchFilter[param].join(',')
                  break
              }
            } else {
              switch (param) {
                case 'StartTime':
                case 'EndTime':
                  query[param] = this.$dayjs(this.SearchFilter[param]).unix()
                  break
                case 'ReceiverDate':
                  query[param] = this.$dayjs(this.SearchFilter[param]).format('YYYY-MM-DD')
                  break
                case 'UTM':
                  for (const [key, val] of Object.entries(this.SearchFilter[param])) {
                    if (val !== '') query[key] = val
                  }
                  break
              }
            }
            break
          case "string":
          case "number":
            switch (param) {
              case 'StartTime':
              case 'EndTime':
                query[param] = this.$dayjs(this.SearchFilter[param]).unix()
                break
              default:
                if (this.SearchFilter[param] !== '') {
                  query[param] = this.SearchFilter[param]
                }
                break
            }
            break
        }
      })
      return this.$router.replace({
        path: '/products/stock/import',
        query
      }).then(() => {
        this.Init()
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseIndex = this.List.map(data => {
          return data.OrderNum
        })
      } else {
        this.CheckAllValue = false
        this.ChooseIndex = []
      }
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      if (id === 'OrderNum') {
        newTag = newTag.trim().toUpperCase()
      }
      this.SearchFilter[id].push(newTag)
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).subtract(1, 'month').unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).unix() * 1000,
        ReceiverDate: '',
        SearchAllTimeRange: false,
        OrderNum: [],
        Payment: [],
        Logistics: [],
        PaymentSystem: [],
        LogisticsSystem: [],
        PaymentStatus: [],
        LogisticsStatus: [],
        OrderStatus: [],
        Account: [],
        Buyer: '',
        Receiver: '',
        BuyerPhone: '',
        ReceiverPhone: '',
        BuyerEmail: '',
        ReceiverEmail: '',
        CustomSeriesNum: [],
        ProductName: '',
        OfferType: [],
        OfferID: [],
        InvoiceType: [],
        InvoiceNumber: '',
        LogisticsNum: [],
        Origin: [],
        utm_id: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: ''
      }
      this.ChooseIndex = []
      return this.$router.replace({
        path: '/products/stock/import'
      }).then(() => {
        this.Init()
      })
    },
    OpenWindow(_link) {
      return window.open(_link, '_blank');
    },
    ApplyTimeRange (StartTime, EndTime, FilterParameters) {
      if (FilterParameters) {
        this.SearchFilter.ReceiverDate = this.$dayjs(StartTime).format('YYYY-MM-DD').toString()
      } else {
        this.SearchFilter.StartTime = this.$dayjs(StartTime).unix() * 1000
        this.SearchFilter.EndTime = this.$dayjs(EndTime).unix() * 1000
      }
    },
    OpenStockImportOrder(item) {
      if (item) {
        this.CurrentOrderID = item._id
      }
      this.Component = () => import('@/components/product/StockImportOrder')
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    },
    PutStock (item) {
      this.$swal({
        icon: 'info',
        title: this.$t('Message.Product/AskPutStock'),
        text: this.$t('Message.Product/AskPutStockDescription'),
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.Submit = true
          const OrderData = await this.$store.dispatch('InnerRequest', {
            url: '/service/data',
            method: 'post',
            data: {
              URL: '/order/detail',
              Data: {
                ID: item._id
              }
            }
          }).then(({ data }) => {
            return data
          })
          return this.$store.dispatch('InnerRequest', {
            url: '/service/action',
            method: 'post',
            data: {
              URL: '/order/edit',
              Data: {
                ID: item._id,
                UpdateData: {
                  'Status.Order': 'Y',
                  ProductInfo: OrderData.ProductInfo.map((info) => {
                    info.Quantity = info.ExpectedQuantity * -1
                    return info
                  })
                }
              }
            }
          }).then(() => {
            this.Submit = false
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Product/SuccessAddImportOrderAndImportItem'),
              type: 'success'
            })
            this.Init()
          }).catch((error) => {
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Global/ErrorMessage') + error.msg,
              type: (error.type ? error.type : 'error')
            })
          })
        }
      })
    }
  }
}
</script>

<style>
#OrderList thead th:first-child {
  text-align: center;
}

#OrderList tbody td:first-child {
  vertical-align: middle;
}
</style>
